.SpecialInterestIcon_s1r9h6rg{height:62px;margin-bottom:1px;}.SpecialInterestIcon_s1r9h6rg path{fill:var(--s1r9h6rg-0);}
.RCASanctionIcon_r9uuv6v{height:62px;margin-bottom:1px;}.RCASanctionIcon_r9uuv6v path{fill:var(--r9uuv6v-0);}
.RCAWatchlistIcon_ruo4vfu{height:62px;margin-bottom:1px;}.RCAWatchlistIcon_ruo4vfu path{fill:var(--ruo4vfu-0);}
.RCAPepsIcon_r1n3o8je{height:62px;margin-bottom:1px;}.RCAPepsIcon_r1n3o8je path{fill:var(--r1n3o8je-0);}
.RCASpecialInterestIcon_r15amm1d{height:62px;margin-bottom:1px;}.RCASpecialInterestIcon_r15amm1d path{fill:var(--r15amm1d-0);}
.ExplainerTooltipSection_ehn0b4c{padding-top:16px;}
.Category_ckaghux{font-size:0.875rem;}
.SpecialInterestHeaderLabel_s10vl74{margin:0 0 6px;}
.SpecialInterestCategoriesAnalystNotesHeaderLabel_sq21v5t{font-size:0.875rem;margin:0 0 6px;}
.SpecialInterestCategoriesList_s1u6q61r{padding-left:28px;margin:0;}
.SpecialInterestPopoverContent_st3q76o{font-size:0.875rem;padding:16px 12px;max-height:270px;overflow-y:auto;}
.MultipleSpecialInterestPopoverContent_m171qfz7.SpecialInterestPopoverContent_st3q76o{background-color:#ffffff;padding:0;}
.AnalystNote_a1927r3v{margin-top:10px;}
.Wrapper_ws83ka{margin:14px 16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;}
.SIContainer_s1rm921i{margin:14px;border-bottom:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;background-color:#fbfbfb;}
.Divider_dyjm9am{width:100%;margin:10px 0 10px 0;height:2px;border-top-color:#d2d3d8;}
.LowerThresholdItem_lh6yy2v{list-style-type:none;padding:0;font-size:0.875rem;color:#474952;font-weight:bold;}.LowerThresholdItem_lh6yy2v::before{content:"– ";}
