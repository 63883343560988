@media (max-width:767px){.WarningContainer_wff6ti6{width:100vw;height:100vh;overflow:hidden;}}
.OuterContainer_o1c86kd9{position:fixed;top:0;left:0;z-index:2000;width:100vw;height:100vh;background:#00162b;display:none;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}@media (max-width:767px){.OuterContainer_o1c86kd9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}}
.InnerContainer_i1isam77{max-width:375px;padding:31px 32px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:10px;}
.Content_cz0entf{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:10px;}
.Heading_h1ipbivv{font-size:1.125rem;}
.Description_diw5v8x{color:#ffffff;font-size:0.875rem;text-align:center;}
.Highlight_h1ap6s6z{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#02a3d5;}
.IconContainer_i1y042d7{background:var(--i1y042d7-0);border-radius:50%;width:100px;height:100px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.Icon_ifiqytz{width:42px;height:42px;}
.ContactButton_c1afrkxy:not(:hover,:focus){background-color:#02a3d5;color:#ffffff;}.ContactButton_c1afrkxy:not(:hover,:focus) path{stroke:#ffffff;}
