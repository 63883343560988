.CustomModalContainer_c1a8g0rq .modal-content{padding:3rem;}
.XapienLogo_xotj9va{width:48px;height:48px;}.XapienLogo_xotj9va path{fill:#28effa;}
.Container_cjxeyn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.Link_l1wxmen9{color:#02a3d5;-webkit-text-decoration:none;text-decoration:none;cursor:pointer;}
.Inputs_imxf0xv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;gap:1rem;width:var(--imxf0xv-0);}.Inputs_imxf0xv input{width:100%;}
.Title_tkbtxpr{color:#ffffff;}
.Subtitle_srn76et{color:#ffffff;}
.Description_d1a8owwb{margin:0;text-align:center;color:#ffffff;}
.Contact_c12z6u1z{margin:0;text-align:center;color:#b3b5bd;}.Contact_c12z6u1z a{color:#28e4fa;}
.BackButton_bw27nyd{all:unset;position:absolute;top:1rem;right:1rem;cursor:pointer;}.BackButton_bw27nyd:not(:focus-visible){outline:none;}
.Main_m22d3uz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.SignInButton_sbmu2km{background-color:#02a3d5;color:#ffffff;height:30px;width:200px;}
