.Panel_p1mc7xnp{position:relative;width:100%;background-color:#EDEDED;min-height:30px;color:#474952;background-color:#ffffff;border-bottom-right-radius:12px;border-bottom-left-radius:12px;page-break-before:avoid;page-break-after:avoid;page-break-inside:avoid;}
.ContentPanel_ctpwfd0.Panel_p1mc7xnp{border-bottom-left-radius:10px;border-bottom-right-radius:10px;}
.Container_c1nxu0wh{min-height:600px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.Divider_d1eyaqo2{margin:0;background-color:#EDEDED;}
.LogoContainer_l9agkks{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:28px;height:28px;background-color:#ffffff;border-radius:50%;}.LogoContainer_l9agkks svg{width:13px;height:16px;color:#000000;}
.DisclaimerButton_d1q60v25{background:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-left:6px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-style:none;color:#02a3d5;}.DisclaimerButton_d1q60v25:focus{outline:none;}
.InformationIcon_ia2n74o{height:14px;width:14px;margin-left:6px;}.InformationIcon_ia2n74o path{fill:#02a3d5;}
.DisclaimerContainer_d11x6lkq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-feature-settings:"clig" off,"liga" off;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;line-height:0.875rem;}.DisclaimerContainer_d11x6lkq > strong{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.EducationModalButton_e1urihxa.DisclaimerButton_d1q60v25{color:#7439B3;}
.Banner_b1d6uffd{background-color:#EDEDED;color:#474952;-webkit-letter-spacing:-0.49px;-moz-letter-spacing:-0.49px;-ms-letter-spacing:-0.49px;letter-spacing:-0.49px;box-shadow:0px 1px 1px 0px rgba(#000000.25);-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;padding:6px 12px;height:36px;font-size:0.875rem;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}.Banner_b1d6uffd > span{display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:horizontal;}
.BannerTextContainer_b1ux8h9k{-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;}
.BannerCta_boggtwc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;}
